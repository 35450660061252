import { Box, Stack, Typography, IconButton } from "@mui/material";
import React, { useContext, useRef } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import CopyIcon from "../../../components/Common/svgLogs/CopyIcon";
import DownloadIconLogo from "../../../components/Common/svgLogs/DownloadIconLogo";
import InputField from "../InputField";
import Loader from "../Loader";
import { PDFDownloadLink, Document, Text, Page, StyleSheet } from "@react-pdf/renderer";
import axios from "axios";
import { Context } from "../../../context/setting";
import { Api } from "../../../apis";

const ChatBox = ({
    setPdfModal,
    details,
    msgList,
    setMsgList,
    containerRef,
    pdfModal,
    files,
}: {
    setPdfModal: (value: boolean) => void;
    details: any;
    msgList: any[];
    setMsgList: (value: any) => void;
    containerRef: any;
    pdfModal: boolean;
    files: any[];
}) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const pdfStyles = StyleSheet.create({
        page: {
            backgroundColor: "#FFFFFF",
            padding: 20,
        },
        text: {
            fontSize: 13,
        },
    });

    const handleSend = (value: string) => {
        setMsgList((prev: any[]) => [...prev, { out: true, message: value }, { in: true, msg: "loading", load: true }]);
        setTimeout(() => {
            containerRef?.current?.lastElementChild?.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "end",
            });
        }, 100);
        Api?.chatMed({
            subject_id: Number(details?.hospitalId),
            hospital_admission_id: Number(details?.patientId),
            query: value,
        })
            ?.then((res: any) => {
                console.log(res);
                if (res?.status == 202) {
                    handleDetail(res?.data?.task_id);
                } else {
                    setMsgList((prev: any[]) => {
                        return prev?.map((items) => {
                            if (items?.load) {
                                items.load = false;
                                items.msg = res?.message || "Failed To Generate Response!";
                                items.err = true;
                            }
                            return items;
                        });
                    });
                    setTimeout(() => {
                        containerRef?.current?.lastElementChild?.scrollIntoView({
                            behavior: "smooth",
                            block: "end",
                            inline: "end",
                        });
                    }, 100);
                }
            })
            ?.catch((err) => {
                setMsgList((prev: any[]) => {
                    return prev?.map((items) => {
                        if (items?.load) {
                            items.load = false;
                            items.msg = err?.response?.data?.detail || err?.message;
                            items.err = true;
                        }
                        return items;
                    });
                });
                setTimeout(() => {
                    containerRef?.current?.lastElementChild?.scrollIntoView({
                        behavior: "smooth",
                        block: "end",
                        inline: "end",
                    });
                }, 100);
            });
    };

    const handleCopy = (msg: string) => {
        navigator.clipboard
            ?.writeText(msg)
            ?.then(() =>
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "success",
                        message: "Message Copied",
                    },
                })
            )
            ?.catch((err) =>
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message: err?.message || "Please try again later",
                    },
                })
            );
    };

    let timeout: NodeJS.Timeout | undefined;
    const handleDetail = (task_id: string) => {
        clearTimeout(timeout);
        Api?.checkInitStatus(task_id)
            ?.then((res: any) => {
                if (res?.status === 200) {
                    if (res?.data?.response?.status === "running") {
                        setTimeout(() => {
                            handleDetail(task_id);
                        }, 3000);
                    } else if (res?.data?.response?.status === "completed") {
                        setMsgList((prev: any[]) => {
                            let result = [...prev];
                            result[result?.length - 1].load = false;
                            result[result?.length - 1].error = false;
                            result[result?.length - 1].msg = res?.data?.response?.result?.response;
                            return result;
                        });
                    }
                    setTimeout(() => {
                        containerRef?.current?.lastElementChild?.scrollIntoView({
                            behavior: "smooth",
                            block: "end",
                            inline: "end",
                        });
                    }, 10);
                } else {
                    setMsgList((prev: any[]) => {
                        let result = [...prev];
                        result[0].load = false;
                        result[0].error = true;
                        result[0].element = "Failed to fetch details";
                        return result;
                    });
                    setTimeout(() => {
                        containerRef?.current?.lastElementChild?.scrollIntoView({
                            behavior: "smooth",
                            block: "end",
                            inline: "end",
                        });
                    }, 10);
                }
            })
            ?.catch((err: any) => {
                setMsgList((prev: any[]) => {
                    let result = [...prev];
                    result[0].load = false;
                    result[0].error = true;
                    result[0].element = "Failed to fetch details";
                    return result;
                });
            });
    };

    return (
        <Box sx={{ display: "flex", justifyContent: "center", height: "100%", p: 1.5 }}>
            <Box sx={{ width: "60rem", display: "flex", flexDirection: "column", gap: 0.5 }}>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    sx={{ border: "1px solid #EEEEEE", background: "#FEFDFD", borderRadius: "8px", p: 1 }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            fontFamily: "Gilroy-SemiBold",
                            gap: 1,
                            flex: 1,
                        }}
                    >
                        <Typography sx={{ color: "#007BFF" }}>Hospital Name Database</Typography>
                        <Typography>
                            Ask questions related to Hospital ID: {details?.hospitalId} Patient ID: {details?.patientId}
                        </Typography>
                    </Box>
                    {!pdfModal && (
                        <IconButton
                            sx={{
                                borderRadius: "10px",
                                p: 1,
                                border: "1px solid #F5F5F5",
                            }}
                            onClick={() => {
                                if (files?.length > 0) {
                                    setPdfModal(true);
                                } else {
                                    setContext({
                                        ...contextStore,
                                        snackState: {
                                            open: true,
                                            errorType: "error",
                                            message: "No Documents Matched",
                                        },
                                    });
                                }
                            }}
                        >
                            <svg
                                width="21"
                                height="21"
                                viewBox="0 0 21 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <mask
                                    id="mask0_10965_2335"
                                    style={{ maskType: "alpha" }}
                                    maskUnits="userSpaceOnUse"
                                    x="0"
                                    y="0"
                                    width="21"
                                    height="21"
                                >
                                    <rect width="21" height="21" fill="#D9D9D9" />
                                </mask>
                                <g mask="url(#mask0_10965_2335)">
                                    <path
                                        d="M6.125 14.875H12.25V13.125H6.125V14.875ZM6.125 11.375H14.875V9.625H6.125V11.375ZM6.125 7.875H14.875V6.125H6.125V7.875ZM4.375 18.375C3.89375 18.375 3.48177 18.2036 3.13906 17.8609C2.79635 17.5182 2.625 17.1062 2.625 16.625V4.375C2.625 3.89375 2.79635 3.48177 3.13906 3.13906C3.48177 2.79635 3.89375 2.625 4.375 2.625H16.625C17.1062 2.625 17.5182 2.79635 17.8609 3.13906C18.2036 3.48177 18.375 3.89375 18.375 4.375V16.625C18.375 17.1062 18.2036 17.5182 17.8609 17.8609C17.5182 18.2036 17.1062 18.375 16.625 18.375H4.375ZM4.375 16.625H16.625V4.375H4.375V16.625Z"
                                        fill="#484848"
                                    />
                                </g>
                            </svg>
                        </IconButton>
                    )}
                </Stack>
                <Box
                    sx={{
                        flex: "1 0 10vh",
                        overflow: "auto",
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        fontSize: "0.9rem",
                        fontFamily: "Gilroy-SemiBold",
                        my: 0.8,
                        mx: 0.3,
                        pb: 1,
                        pr: 0.5,
                        borderRadius: "8px",
                    }}
                    ref={containerRef}
                >
                    {msgList?.map((item: any, index: number) => (
                        <>
                            {item?.in && (
                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                    <Box
                                        sx={{
                                            p: 1.5,
                                            background: "rgba(217, 217, 217, 0.3)",
                                            borderRadius: "8px",
                                            width: "75%",
                                            alignSelf: item?.load ? "center" : "flex-start",
                                            color: item?.err ? "#fe6767" : "inherit",
                                            minHeight: "3rem",
                                        }}
                                    >
                                        {item?.init ? (
                                            <>
                                                {item?.load ? (
                                                    <Stack justifyContent={"center"} gap={1.5}>
                                                        <Typography variant="caption" fontWeight={"bold"}>
                                                            Please Wait, Fetching Details...
                                                        </Typography>
                                                        <Loader />
                                                    </Stack>
                                                ) : (
                                                    <Typography
                                                        sx={{
                                                            fontSize: "0.9rem",
                                                            color: item?.error ? "red" : "inherit",
                                                            whiteSpace: "pre-line",
                                                            p: 2,
                                                        }}
                                                    >
                                                        <Markdown remarkPlugins={[remarkGfm]}>{item?.element}</Markdown>
                                                    </Typography>
                                                )}
                                            </>
                                        ) : item?.load ? (
                                            <Loader />
                                        ) : (
                                            item?.msg?.split("\n")?.map((msg: string) => (
                                                <>
                                                    {msg}
                                                    <br />
                                                </>
                                            ))
                                        )}
                                    </Box>
                                    {item?.hasOwnProperty("load") && !item?.load && (
                                        <Stack
                                            sx={{ position: "sticky", top: "0", alignSelf: "flex-start" }}
                                            direction={"row"}
                                        >
                                            <Box>
                                                <IconButton
                                                    sx={{
                                                        transition: "ease-in-out 200ms",
                                                        transform: "scale(1)",
                                                        "&:hover": {
                                                            background: "#fff",
                                                            transform: "scale(1.1)",
                                                        },
                                                    }}
                                                    onClick={() => handleCopy(item?.msg || item?.element)}
                                                >
                                                    <CopyIcon />
                                                </IconButton>
                                            </Box>
                                            <Box>
                                                <PDFDownloadLink
                                                    document={
                                                        <Document>
                                                            <Page size="A4" style={pdfStyles?.page}>
                                                                {(item?.msg || item?.element)
                                                                    ?.split("\n")
                                                                    ?.map((msg: string) => (
                                                                        <Text style={pdfStyles?.text}>{msg}</Text>
                                                                    ))}
                                                            </Page>
                                                        </Document>
                                                    }
                                                    fileName={`Medical-Summarizer response...`}
                                                >
                                                    {({ blob, url, loading, error }) =>
                                                        loading ? (
                                                            <Loader />
                                                        ) : (
                                                            <IconButton
                                                                sx={{
                                                                    transition: "ease-in-out 200ms",
                                                                    transform: "scale(1)",
                                                                    "&:hover": {
                                                                        background: "#fff",
                                                                        transform: "scale(1.1)",
                                                                    },
                                                                }}
                                                            >
                                                                <DownloadIconLogo />
                                                            </IconButton>
                                                        )
                                                    }
                                                </PDFDownloadLink>
                                            </Box>
                                        </Stack>
                                    )}
                                </Box>
                            )}
                            {item?.out && (
                                <Box
                                    sx={{
                                        p: 1,
                                        background: "#D2E8FF",
                                        borderRadius: "13px",
                                        width: "60%",
                                        alignSelf: "flex-end",
                                    }}
                                >
                                    {item?.message}
                                </Box>
                            )}
                        </>
                    ))}
                </Box>
                <Box>
                    <InputField handleSend={handleSend} />
                </Box>
            </Box>
        </Box>
    );
};

export default ChatBox;
